@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

* {
  font-family: 'Open Sans', Helvetica, sans-serif;
  color: '#191919';
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

#root {
  overflow: hidden;
  height: 100vh;
  position: relative;
}

.fakeLink {
  color: #8AB4F8;
  text-decoration-line: underline;
  cursor: pointer;
}

.fade-in-show {
  opacity: 1;
  transition:  .2s ease-in-out;
  margin-top: 0px;

}

.fade-in-hide {
  opacity: 0;
  transition:  .2s ease-in-out;
  margin-top: -20px;

}

.fade-in-show-opacity {
  opacity: 1;
  transition:  .2s ease-in-out;

}

.fade-in-hide-opacity {
  opacity: 0;
  transition:  .2s ease-in-out;

}

.pianoHighlight {
  background-color: transparent;
}

.pianoHighlight:hover {
  background-color: #e6eaf0;
}

.bookDesc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.antd-table-row-clickable {
  cursor: pointer;
}